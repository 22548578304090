@import 'mixins/media-queries';

.center-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-align: center;
}

.align-right {
  text-align: right;
}

.mw {
  max-width: $component-max-width;
  margin: auto;
}

[hidden] {
  visibility: hidden;
}

.hide-xs {
  @include mq(xs, max) {
    display: none !important;
  }
}

.show-only-on-touch-primary {
  .isMousePrimary & {
    display: none !important;
  }
}

.show-only-on-mouse-primary {
  .isTouchPrimary & {
    display: none !important;
  }
}

.show-only-on-touch-only {
  .isNoTouchOnly & {
    display: none !important;
  }
}

.show-only-on-no-touch-only {
  .isTouchOnly & {
    display: none !important;
  }
}

.show-xs-only {
  @include mq(xs) {
    display: none !important;
  }
}

.hide-gt-sm {
  @include mq(sm, max) {
    display: none !important;
  }
}

.show-xs-only {
  @include mq(xs) {
    display: none !important;
  }
}

.show-gt-sm-only {
  @include mq(sm) {
    display: none !important;
  }
}

.isWebVersion .isHideForWebVersion {
  display: none !important;
}

.isElectron .isHideForElectron {
  display: none !important;
}

.isNoAdvancedFeatures .isHideForNoAdvancedFeatures {
  display: none !important;
}

.isAdvancedFeatures .isHideForAdvancedFeatures {
  display: none !important;
}
