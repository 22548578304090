.markdown-preview,
.markdown {
  @include scrollY;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  hyphens: auto;

  *:first-child {
    margin-top: 0;
    padding-top: 0;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  ul {
    margin: 0 $s;
    padding-left: $s * 2;
    padding-inline-start: 20px;
  }

  > ul {
    padding-left: $s * 2;
  }

  ul ul {
    padding-top: 0;
    padding-left: $s * 2;
    padding-inline-start: $s * 2;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1;
    margin-top: 16px;
  }

  h1,
  h2,
  h3 {
    &,
    .mat-typography & {
      margin-bottom: $s;
      margin-top: $s * 2;

      &:first-child {
        margin-top: 0;
        padding-top: 0;
      }
    }
  }

  h1 {
    font-size: 22px;
    line-height: 24px;
  }

  h2 {
    font-size: 18px;
    line-height: 22px;
  }

  pre {
    max-width: 100%;
    overflow-x: auto;
    // we need this, otherwise it might break inside tables
    white-space: pre-wrap;

    code {
      font-size: 12px;
    }
  }

  input[type='checkbox'] {
    margin-left: -20px;
    pointer-events: none;
  }

  .checkbox {
    display: inline-block;
    font-size: 20px;
    width: 20px;
    line-height: 1;
    text-align: center;
    margin-left: -28px;
    vertical-align: middle;
    margin-right: $s;
  }

  .checkbox-wrapper {
    list-style: none;
    margin-bottom: $s;
  }
}
