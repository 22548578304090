@import '../../variables';

.issue-table {
  width: 100%;
  display: block;
  text-align: left;

  pre {
    overflow: auto;
    border: 1px dashed $light-theme-extra-border-color;
    padding: $s;
  }

  tr {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $s * 1.5;
  }

  td,
  th {
    vertical-align: top;
    padding-bottom: $s;
    display: block;

    &:first-child {
      padding-left: 0;
    }
  }

  th {
    min-width: 80px;
    padding-left: 12px;
    text-align: left;
    font-style: italic;
  }

  // to shrink the th col to a min
  td {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
    hyphens: auto;
    padding-left: 0;

    &,
    *,
    .markdown * {
      user-select: text;
    }

    ::ng-deep img,
    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
  }
}
